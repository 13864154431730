// i18next-extract-mark-ns-start pricing

import styled from 'styled-components';
import {ScreenSizes} from '../../../types/responsive';
import {Content} from '../Content';
import {StyledDiv, StyledLi} from '../StyledSx';

export const PricingBackground = styled.div`
  margin-top: 100px;
  padding: 180px 0 100px;
  position: relative;
  scroll-behavior: smooth;

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: skew(0, -10deg);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #41efd6 0%, #aab4e9 90%);
  }
  &:after {
    display: block;
    position: absolute;
    content: '';
    z-index: -1;
    right: 0;
    left: 0;
    bottom: 0;
    height: 80%;
    background-image: linear-gradient(30deg, #41efd6 0%, #aab4e9 100%);
  }

  @media (max-width: ${ScreenSizes.md}) {
    padding: 60px 0 100px;
  }

  @media (max-width: ${ScreenSizes.sm}) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
`;

export const PricingContent = styled(Content)`
  max-width: 1400px;

  @media (max-width: ${ScreenSizes.md}) {
    padding: 0;
  }
`;

export const PricingSection = styled.div`
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  border-radius: ${(props) => props.theme.borderRadius};
  line-height: 1.45em;

  &.keen-slider__slide {
    overflow: auto;
  }

  @media (max-width: ${ScreenSizes.sm}) {
    box-shadow: none;
    background: none;
    margin: 0;
  }
`;

export const PlansTable = styled.div`
  position: relative;
  padding: 0 20px 0 28%;

  @media (max-width: 1280px) {
    padding: 0;
  }
`;

export const Plan = styled(StyledDiv)`
  margin: -30px 5px 10px 5px;
  position: relative;
  z-index: 1;
  flex: 1;
  background: #ffffff;
  box-shadow: ${(props) => props.theme.boxShadow};
  border-radius: ${(props) => props.theme.borderRadius};

  @media (max-width: 1280px) {
    margin: 10px;
  }
`;

export const PlanHeader = styled.div`
  padding: 60px 40px 40px;
  height: 520px;
`;

export const PlanPrice = styled.div`
  font-size: 1.3rem;
  margin: 1.45rem 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  p {
    font-size: 1rem;
    margin: 0;
  }
  span {
    position: relative;
  }
`;

export const PlanTitle = styled.h2`
  font-weight: 200;
  font-size: 2rem;
  margin-bottom: 1.45rem;
`;

export const PlanLabel = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const PlanFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const PlanAction = styled.div`
  padding: 40px;
  text-align: center;
`;

export const PlanLabels = styled.ul`
  padding: 0;
  position: absolute;
  top: 490px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 100%;

  li:after {
    display: block;
    content: '';
    width: 75%;
    @media (max-width: ${ScreenSizes.md}) {
      width: 100%;
    }
  }
  @media (max-width: 1280px) {
    display: none;
  }
`;

export const ListRow = styled(StyledLi)`
  padding: 10px 40px;
  align-items: center;
  min-height: 66px;
  display: -webkit-box;
  span {
    width: 24%;
  }
  :nth-child(even) {
    background: #f5f5f5;
  }
  img {
    margin: auto 0;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding: 10px 24px;
    display: flex;
    img {
      margin: 0 8px 0 0;
    }
  }
`;

export const CheckMark = styled.img`
  width: 18px;
  min-width: 18px;
`;

export const PlansContainer = styled.div`
  flex: 1;
`;

export const PlansList = styled(StyledDiv)`
  display: flex;
  justify-content: space-around;
  @media (max-width: ${ScreenSizes.lg}) {
    justify-content: initial;
    overflow-x: scroll;
  }
  @media (max-width: ${ScreenSizes.md}) {
    overflow: visible !important;
  }
`;

export const PricingFooter = styled.div`
  padding: 40px 40px 20px;

  @media (max-width: 768px) {
    margin-top: 50px;
    background: #ffffff;
    box-shadow: ${(props) => props.theme.boxShadow};
    border-radius: ${(props) => props.theme.borderRadius};
  }
`;

export const MobilePlanLabel = styled(PlanLabel)`
  display: none;
  width: 100%;
  @media (max-width: ${ScreenSizes.lg}) {
    display: block;
    margin-bottom: 5px;
  }
`;

export const MdPlanLabel = styled(PlanLabel)`
  display: none;
  width: 100%;
  @media (max-width: ${ScreenSizes.md}) {
    display: block;
    margin-bottom: 5px;
  }
`;

export const SmPlanLabel = styled(PlanLabel)`
  display: none;
  width: 100%;
  @media (max-width: ${ScreenSizes.sm}) {
    display: block;
    margin-bottom: 5px;
  }
`;
